<template>
  <div class="app">
    <Navigation></Navigation>
    <router-view></router-view>
  </div>


  <footer>
    <p>© 2023 Jorge Castanho. All Rights Reserved. </p>
  </footer>




</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Navigation from "./components/Navigation.vue";
export default {
  name: "App",
  components: {
    Navigation,
  },

};
</script>

<style lang="scss">
// * {
//   padding: 0;
//   margin: 0;
//   box-sizing: border-box;
//   font-family: "Raleway", sans-serif;
//   font-weight: 400;
// }

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
  margin: 0%;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

// .divider {
//   position: absolute;
//   left: 12.71%;
//   right: 12.71%;
//   top: 66.33%;
//   bottom: 33.5%;

//   /* blue navy */

//   background: #5b1c9f;
//   opacity: 0.1;
// }

footer {
  //border-top: 2px solid #5b1c9f;
  position: relative;
  //width: 1440px;
  //height: 10px;

  background: #FFFFFF;
}

p {
  position: relative;
  text-align: center;
  margin-top: 5%;
}
</style>
