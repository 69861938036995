<template>
  <header :class="{ 'scrolled-nav': scrolledNav }">
    <link rel="preconnect" href="http://fonts.googleapis.com" />
    <link rel="preconnect" href="http://fonts.gstatic.com" crossorigin />
    <link
      href="http://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap"
      rel="stylesheet"
    />

    <nav>
      <div class="branding">
        <router-link :to="{ name: 'Home' }"
          ><img id="logo" alt="Logo" src="../assets/logofull.png"
        /></router-link>
      </div>
      <ul v-show="!mobile" class="navigation">
        <li>
          <router-link class="link" :to="{ name: 'Home' }"
            >The Company</router-link
          >
        </li>
        <li>
          <router-link class="link" :to="{ name: 'Portfolio' }"
            >Portfolio</router-link
          >
        </li>
        <li>
          <router-link class="link" :to="{ name: 'About' }">Contact</router-link>
        </li>
      </ul>
      <div class="icon">
        <i
          @click="toggleMobileNav"
          v-show="mobile"
          class="fas fa-bars"
          :class="{ 'icon-active': mobileNav }"
        ></i>
      </div>
      <transition name="mobile-nav">
        <ul v-show="mobileNav" class="dropdown-nav">
          <li>
            <router-link
              v-on:click="toggleMobileNav()"
              class="link"
              :to="{ name: 'Home' }"
              >The Company</router-link
            >
          </li>
          <li>
            <router-link
              v-on:click="toggleMobileNav()"
              class="link"
              :to="{ name: 'Portfolio' }"
              >Portfolio</router-link
            >
          </li>
          <li>
            <router-link
              v-on:click="toggleMobileNav()"
              class="link"
              :to="{ name: 'About' }"
              >Contact</router-link
            >
          </li>
        </ul>
      </transition>
    </nav>
  </header>
  <!-- <div id="nav">
    <img id="logo" alt="Logo" src="../assets/logosimple.png" />
    <router-link to="/">Home</router-link>|
    <router-link to="/about">About</router-link>
  </div>
  <router-view /> -->
</template>
<script>
export default {
  name: "Navigation",
  data() {
    return {
      scrolledNav: null,
      mobile: true,
      mobileNav: null,
      windowWidth: null,
    };
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  methods: {
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav;
    },

    updateScroll() {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 50) {
        this.scrolledNav = true;
        return;
      }
      this.scrolledNav = false;
      return;
    },

    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 750) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    },
  },
};
</script>
<style lang="scss" scoped>
header {
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 99;
  width: 100%;
  position: sticky;
  transition: 0.5s ease all;
  color: black;
  top: 0;

  nav {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 12px 0;
    transition: 0.5s ease all;
    width: 90%;
    margin: 0 auto;
    @media (min-width: 1140px) {
      max-width: 1140px;
    }

    ul,
    .link {
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      color: black;
      list-style: none;
      text-decoration: none;
    }

    li {
      text-transform: uppercase;
      text-align: center;
      padding: 16px;
      margin-left: 16px;
      width: 103px;
    }

    .link {
      font-size: 15px;
      transition: 0.5s ease all;
      padding-bottom: 4px;
      border-bottom: 1px solid transparent;
      &:hover {
        color: #5b1c9f;
        border-color: #5b1c9f;
      }
    }

    .branding {
      display: flex;
      align-items: center;

      img {
        width: 30%;
        transition: 0.5s ease all;
      }
    }

    .navigation {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: flex-end;
    }

    .icon {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      right: 24px;
      height: 100%;

      i {
        cursor: pointer;
        font-size: 24px;
        transition: 0.8s ease all;
      }
    }

    .icon-active {
      transform: rotate(90deg);
    }

    .dropdown-nav {
      display: flex;
      flex-direction: column;
      position: fixed;
      width: 100%;
      max-width: 150px;
      height: 100%;
      background-color: #ffffff;
      top: 0;
      left: 0;

      li {
        margin-left: 0;

        .link {
          color: black;
        }
      }
    }

    .mobile-nav-enter-active,
    .mobile-nav-leave-active {
      transition: 0.5s ease all;
    }

    .mobile-nav-enter-from,
    .mobile-nav-leave-to {
      transform: translateX(-250px);
    }

    .mobile-nav-enter-to {
      transform: translateX(0);
    }
  }
}

.scrolled-nav {
  background-color: white;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);

  nav {
    padding: 2px 0;

    .branding {
      img {
        width: 20%;
      }
    }
  }
}
</style>