<template>
	<!-- PC version -->
	<div v-show="!mobile" class="home">
		<div>

			<!-- carousel -->

			<n-carousel autoplay trigger="hover">
				<n-carousel-item>
					<div class="gradient-overlay">
						<img class="carousel-img" src="../assets/fotos/liquid.jpg" />
					</div>

					<p class="pagetitle centered" style="margin-left: 3%;">
						High quality ALUMINUM TOOLS
					</p>
				</n-carousel-item>
				<n-carousel-item>
					<div class="gradient-overlay">
					<img
						class="carousel-img"
						src="../assets/fotos/cut2.jpg"
					/>
					</div>
					<p class="pagetitle centered">
						Shaping aluminum tools for over 30 years
					</p>
				</n-carousel-item>
				<!-- <n-carousel-item>
					<div class="gradient-overlay">
						<img
						id="textImg"
						src="../assets/fotos/outsidelogo.jpg"
						alt="test"
					/>
					<img
						class="carousel-img"
						src="../assets/fotos/truckentrance.jpg"
					/>
					</div>
				</n-carousel-item> -->
			</n-carousel>

			<!-- end carousel -->
			<div class="textBoxesContainer">
				<div class="textBoxes">
					<div class="textPart">
						<p class="title">
							Over <a class="purpleWords">30 years</a> building
							aluminum tools
						</p>
						<p class="infoText">
							We are a leading manufacturer of high-quality
							aluminum molds for a variety of industries,
							including automotive, aerospacial, consumer goods,
							and more.
						</p>
					</div>
					<img
						id="textImg"
						src="../assets/fotos/machineoutside.jpg"
						alt="test"
					/>
				</div>
				<div class="textBoxes">
					<img
						id="textImgAlternate"
						src="../assets/fotos/test2.jpg"
						alt="test"
					/>
					<div class="textPartAlternate">
						<p class="titleAlternate">
							Ready to
							<a class="purpleWords">Shape Aluminum Tools</a> for
							your needs
						</p>
						<p class="infoTextAlternate">
							Our commitment to excellence in design and
							manufacturing has earned us a reputation for
							delivering products that are durable, precise, and
							reliable.
						</p>
					</div>
				</div>
				<div class="textBoxes">
					<div class="textPart">
						<p class="title">
							<a class="purpleWords">Prototypes</a> or
							<a class="purpleWords">Serial</a> tools
						</p>
						<p class="infoText">
							We are able to handle and produce tools up to 10
							tons of weight.<br />
						</p>
					</div>
					<img
						id="textImg"
						src="../assets/fotos/machine.jpg"
						alt="test"
					/>
				</div>
			</div>
		</div>
	</div>

	<!-- Mobile version -->
	<div v-show="mobile" class="homeMobile">
		<div>
			<!-- carousel -->

			<n-carousel autoplay trigger="hover">
				<n-carousel-item>
					<img class="carousel-img" src="../assets/fotos/cut2.jpg" />

					<p class="pagetitleMobile centeredMobile">
						<a class="">High quality</a> ALUMINUM TOOLS
					</p>
				</n-carousel-item>
				<n-carousel-item>
					<img
						class="carousel-img"
						src="../assets/fotos/liquid.jpg"
					/>
					<p class="pagetitleMobile centeredMobile">
						Shaping aluminum tools for over 30 years
					</p>
				</n-carousel-item>
				<!-- <n-carousel-item>
					<img
						class="carousel-img"
						src="../assets/fotos/outsidelogo.jpg"
					/>
					<img
						class="carousel-img"
						src="../assets/fotos/outsidelogo.jpg"
					/>
				</n-carousel-item> -->
			</n-carousel>

			<!-- end carousel -->
			<div class="textBoxesContainerMobile">
				<div class="textBoxesMobile">
					<div class="textPart">
						<p class="title">
							Over <a class="purpleWords">30 years</a> building
							aluminum tools
						</p>
						<p class="infoText">
							We are a leading manufacturer of high-quality
							aluminum molds for a variety of industries,
							including automotive, aerospacial, consumer goods,
							and more.
						</p>
					</div>
					<img
						id="textImgMobile"
						src="../assets/fotos/machineoutside.jpg"
						alt="test"
					/>
				</div>
				<div class="textBoxesMobile">

					<div class="textPart">
						<p class="title">
							Ready to
							<a class="purpleWords">Shape Aluminum Tools</a> for
							your needs
						</p>
						<p class="infoText">
							Our commitment to excellence in design and
							manufacturing has earned us a reputation for
							delivering products that are durable, precise, and
							reliable.
						</p>
					</div>
					<img
						id="textImgMobile"
						src="../assets/fotos/test2.jpg"
						alt="test"
					/>
				</div>
				<div class="textBoxesMobile">
					<div class="textPart">
						<p class="title">
							<a class="purpleWords">Prototypes</a> or
							<a class="purpleWords">Serial</a> tools
						</p>
						<p class="infoText">
							We are able to handle and produce tools up to 10
							tons of weight.<br />
						</p>
					</div>
					<img
						id="textImgMobile"
						src="../assets/fotos/machine.jpg"
						alt="test"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	// import HelloWorld from '@/components/HelloWorld.vue'
	import { NCarousel } from "naive-ui";
	import { NCarouselItem } from "naive-ui";

	export default {
		name: "Home",
		components: {
			// HelloWorld
			NCarousel,
			NCarouselItem,
		},
		data() {
			return {
				scrolledNav: null,
				mobile: true,
				mobileNav: null,
				windowWidth: null,
			};
		},
		created() {
			window.addEventListener("resize", this.checkScreen);
			this.checkScreen();
		},
		mounted() {
			window.addEventListener("scroll", this.updateScroll);
		},
		methods: {
			toggleMobileNav() {
				this.mobileNav = !this.mobileNav;
			},

			updateScroll() {
				const scrollPosition = window.scrollY;
				if (scrollPosition > 50) {
					this.scrolledNav = true;
					return;
				}
				this.scrolledNav = false;
				return;
			},

			checkScreen() {
				this.windowWidth = window.innerWidth;
				if (this.windowWidth <= 750) {
					this.mobile = true;
					return;
				}
				this.mobile = false;
				this.mobileNav = false;
				return;
			},
		},
	};
</script>

<style lang="scss" scoped>

	@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;700&display=swap');


	.gradient-overlay {
		position: relative;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 99%;
			opacity: 0.5;
			background-image: linear-gradient(to top left, #5b1c9f, black);
		}
	}

	.carousel-img {
		width: 100%;
		//height: 850px;  esta da para full screen
		height: 700px;
		object-fit: cover;
		//margin-bottom: 50px;
	}
	p {
		font-size: 10vmin;
		line-height: 1.1;
		font-family: "Roboto", sans-serif;
		//font-family: "Dank Mono", sans-serif;
		//font-weight: 200px;
		color: black;
	}

	.textBoxesContainer {
		margin-top: 20px;
	}

	#mainImg {
		width: 100%;
		height: 600px;
		object-fit: cover;
		display: block;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 100px;
	}
	#textImg {
		width: 60%;
		height: 600px;
		width: 800px;
		object-fit: cover;
		display: block;
		//float: right;
		//margin-right: 0;
		margin-left: auto;
		//margin-bottom: 30px;
	}
	#textImgAlternate {
		width: 60%;
		height: 600px;
		width: 800px;
		object-fit: cover;
		display: block;
		//float: right;
		//margin-right: 0;
		margin-right: auto;
		//margin-bottom: 30px;
	}
	.pagetitle {
		//text-align: center;
		text-transform: uppercase;
		//float: left;
		font-size: 100px;
		font-weight: 600;
		font-style: normal;
		//color: rgb(70, 70, 70);
		-webkit-text-stroke: 0.1px black;
		//background: -webkit-linear-gradient(#201b20, aliceblue);
		//-webkit-background-clip: text;
		//-webkit-text-fill-color: transparent;
		color: aliceblue;
		//color: #5b1c9f;
		//margin-left: auto;
		text-align: center;
		//text-shadow: 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black;
	}
	.textPart {
		//text-align: center;
		margin-left: auto;
		margin: 80px 50px 80px 50px;
	}
	.textPartAlternate {
		//text-align: center;
		margin-right: auto;
		margin: 80px 50px 80px 50px;
	}
	.purpleWords {
		color: #5b1c9f;
		font-weight: 350;
		//font-style: oblique;
	}
	.title {
		text-transform: uppercase;
		//float: left;
		font-size: 60px;
		//font-weight: 200;
		font-weight: 200;
		font-style: normal;
		color: black;
		margin-left: auto;
		//text-align: center;
	}
	.infoText {
		font-size: 25px;
		font-weight: 300;
		//margin: 50px 20px 0px 20px;
		//text-align: center;
	}
	.titleAlternate {
		text-transform: uppercase;
		//float: left;
		font-size: 60px;
		//font-weight: 200;
		font-weight: 200;
		font-style: normal;
		color: black;
		margin-right: auto;
		//margin-left: 20px;
	}
	.infoTextAlternate {
		font-size: 25px;
		font-weight: 300;
		//margin-top: 50px;
	}
	.textBoxes {
		//margin-top: 50px;
		margin-top: 20px;
		display: flex;
		flex-direction: row;
	}
	/* Container holding the image and the text */
	.container {
		position: relative;
		text-align: center;
		color: white;
	}
	/* Centered text */
	.centered {
		position: absolute;
		top: 25%;
		// left: 50%;
		// transform: translate(-50%, -50%);
	}


	/* Mobile */
	.carousel-img-mobile {
		width: 10%;
		//height: 850px;  esta da para full screen
		height: 700px;
		object-fit: cover;
		//margin-bottom: 50px;
	}

	.pagetitleMobile {
		//text-align: center;
		text-transform: uppercase;
		//float: left;
		//font-size: 100px;
		font-weight: 400;
		font-style: normal;
		//color: rgb(70, 70, 70);
		-webkit-text-stroke: 0.1px black;
		//background: -webkit-linear-gradient(#201b20, aliceblue);
		//-webkit-background-clip: text;
		//-webkit-text-fill-color: transparent;
		color: aliceblue;
		//color: #5b1c9f;
		//margin-left: auto;
		//text-align: center;
		//position: absolute;
		//margin-top: 98%;
		//margin-bottom: 2%;
		//text-shadow: 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black;
	}

	.centeredMobile {
		position: absolute;
		top: 70%;
		// left: 50%;
		// transform: translate(-50%, -50%);
	}

	.textBoxesMobile {
		//margin-top: 50px;
		margin-top: 20px;
		display: block;
		flex-direction: row;
	}

	#textImgMobile {
		width: 100%;
		//height: 600px;
		//width: 800px;
		object-fit: cover;
		display: block;
		//float: right;
		//margin-right: 0;
		margin-left: auto;
		//margin-bottom: 30px;
	}
</style>
